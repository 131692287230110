import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Willkommen bei QuickFix
			</title>
			<meta name={"description"} content={"Schnelle Lösungen, zuverlässige Ergebnisse"} />
			<meta property={"og:title"} content={"Willkommen bei QuickFix"} />
			<meta property={"og:description"} content={"Schnelle Lösungen, zuverlässige Ergebnisse"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09:42:42.304Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09:42:42.304Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09:42:42.304Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09:42:42.304Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09:42:42.304Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09:42:42.304Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-1.jpg?v=2024-07-12T09:42:42.304Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header />
		<Components.Header1>
			<Override slot="link">
				+493062722971
			</Override>
			<Override slot="text">
				Rollbergstraße 28A, 12053 Berlin, Germany
			</Override>
		</Components.Header1>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
			>
				<Text
					font="--headline1"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					QuickFix-Angebot
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					Bei QuickFix PC Repair sind wir stolz darauf, eine umfassende Palette von Dienstleistungen anzubieten, die auf alle Ihre Computerreparaturanforderungen zugeschnitten sind. Unsere Dienstleistungen sind darauf ausgelegt, maximale Effizienz und Zuverlässigkeit zu gewährleisten und die Lebensdauer Ihrer Technologie zu verlängern. Sehen Sie sich unsere detaillierten Angebote an und finden Sie die perfekte Lösung für Ihre technischen Probleme.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					object-fit="cover"
					lg-max-height="230px"
					src="https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-3.jpg?v=2024-07-12T09:42:42.296Z"
					max-height="400px"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-3.jpg?v=2024-07-12T09%3A42%3A42.296Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-3.jpg?v=2024-07-12T09%3A42%3A42.296Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-3.jpg?v=2024-07-12T09%3A42%3A42.296Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-3.jpg?v=2024-07-12T09%3A42%3A42.296Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-3.jpg?v=2024-07-12T09%3A42%3A42.296Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-3.jpg?v=2024-07-12T09%3A42%3A42.296Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-3.jpg?v=2024-07-12T09%3A42%3A42.296Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Diagnose und Fehlerbehebung
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Fehleranalyse: Schnelle Identifizierung von Software- und Hardwarefehlern.
Leistungsoptimierung: Verbesserung von Geschwindigkeit und Effizienz.
Regelmäßige Wartungsprüfungen: Verhindern Sie zukünftige Probleme mit geplanten Diagnosen.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Image
					src="https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-2.jpg?v=2024-07-12T09:42:42.263Z"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					srcSet="https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-2.jpg?v=2024-07-12T09%3A42%3A42.263Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-2.jpg?v=2024-07-12T09%3A42%3A42.263Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-2.jpg?v=2024-07-12T09%3A42%3A42.263Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-2.jpg?v=2024-07-12T09%3A42%3A42.263Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-2.jpg?v=2024-07-12T09%3A42%3A42.263Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-2.jpg?v=2024-07-12T09%3A42%3A42.263Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-2.jpg?v=2024-07-12T09%3A42%3A42.263Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
				>
					Hardwarereparaturen und -upgrades
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
				>
					Komponentenaustausch: Von Festplatten bis hin zu GPUs verwenden wir nur die besten Teile.
Systemupgrades: Steigern Sie die Leistung Ihres PCs mit der neuesten Technologie.
Kundenspezifische Builds: Maßgeschneiderte Systeme, die auf Ihre spezifischen Anforderungen zugeschnitten sind.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				lg-width="40%"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-min-width="64px"
				empty-border-color="LightGray"
				width="33%"
			>
				<Image
					src="https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-1.jpg?v=2024-07-12T09:42:42.259Z"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					srcSet="https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-1.jpg?v=2024-07-12T09%3A42%3A42.259Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-1.jpg?v=2024-07-12T09%3A42%3A42.259Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-1.jpg?v=2024-07-12T09%3A42%3A42.259Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-1.jpg?v=2024-07-12T09%3A42%3A42.259Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-1.jpg?v=2024-07-12T09%3A42%3A42.259Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-1.jpg?v=2024-07-12T09%3A42%3A42.259Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-1.jpg?v=2024-07-12T09%3A42%3A42.259Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
				>
					Softwareinstallation und -konfiguration
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Betriebssysteminstallation: Nahtlose Einrichtung von Windows-, Mac- oder Linux-Systemen.
Softwareupdates: Halten Sie Ihre Anwendungen und Betriebssysteme auf dem neuesten Stand.
Sicherheitslösungen: Installation von Antiviren- und Malware-Schutz zum Schutz Ihrer Daten.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
				md-width="100%"
				lg-width="60%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				empty-border-width="1px"
				width="33%"
				md-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-4.jpg?v=2024-07-12T09:42:42.277Z"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					object-position="bottom"
					srcSet="https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-4.jpg?v=2024-07-12T09%3A42%3A42.277Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-4.jpg?v=2024-07-12T09%3A42%3A42.277Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-4.jpg?v=2024-07-12T09%3A42%3A42.277Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-4.jpg?v=2024-07-12T09%3A42%3A42.277Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-4.jpg?v=2024-07-12T09%3A42%3A42.277Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-4.jpg?v=2024-07-12T09%3A42%3A42.277Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/2-4.jpg?v=2024-07-12T09%3A42%3A42.277Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box sm-padding="0px 0px 0px 0px" md-padding="0px 0px 0px 0px" padding="0px 30% 0px 0px">
					<Text
						md-margin="12px 0px 16px 0px"
						sm-margin="24px 0px 16px 0px"
						margin="32px 0px 16px 0px"
						font="--lead"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					>
						Datenverwaltung
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="--base"
						color="--light"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
						text-align="left"
					>
						Datenwiederherstellung: Rufen Sie verlorene oder beschädigte Daten effektiv ab.
Backup-Lösungen: Richten Sie zuverlässige Datensicherungssysteme ein, um Datenverlust zu verhindern.
Datenübertragung: Sichere Migration von Daten zwischen Geräten.
					</Text>
				</Box>
			</Box>
			<Box
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
				md-width="100%"
				lg-width="60%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				empty-border-width="1px"
				width="33%"
				md-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-2.jpg?v=2024-07-12T09:42:42.272Z"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					object-position="bottom"
					srcSet="https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-2.jpg?v=2024-07-12T09%3A42%3A42.272Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-2.jpg?v=2024-07-12T09%3A42%3A42.272Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-2.jpg?v=2024-07-12T09%3A42%3A42.272Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-2.jpg?v=2024-07-12T09%3A42%3A42.272Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-2.jpg?v=2024-07-12T09%3A42%3A42.272Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-2.jpg?v=2024-07-12T09%3A42%3A42.272Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb17f3f24780021f8b1f4/images/1-2.jpg?v=2024-07-12T09%3A42%3A42.272Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box sm-padding="0px 0px 0px 0px" md-padding="0px 0px 0px 0px" padding="0px 30% 0px 0px">
					<Text
						md-margin="12px 0px 16px 0px"
						sm-margin="24px 0px 16px 0px"
						margin="32px 0px 16px 0px"
						font="--lead"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					>
						Netzwerkeinrichtung und -verwaltung
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="--base"
						color="--light"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
						text-align="left"
					>
						Heim- und Büronetzwerke: Einrichtung und Wartung zuverlässiger Verbindungen.
Sicherheitsprotokolle: Implementierung robuster Netzwerksicherheit zum Schutz vertraulicher Informationen.
Optimierung der Konnektivität: Sicherstellung stabiler und schneller Internetverbindungen.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0 40px 0">
			<Box
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 16px 0px"
				>
					Entdecken Sie mehr mit QuickFix
				</Text>
				<Text
					color="--light"
					font="--base"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					text-align="left"
				>
					Diese Übersicht ist nur ein kleiner Einblick, wie wir Ihr Computererlebnis neu beleben können. Um die Breite unserer Services vollständig zu erkunden und zu erfahren, wie wir Ihnen konkret helfen können, laden wir Sie ein, uns zu kontaktieren oder unseren Shop zu besuchen. Unsere Experten sind bereit, Ihnen persönliche Beratung und maßgeschneiderte Lösungen zu bieten.
					<br />
				</Text>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="linkBox" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text4" />
			<Override slot="text3" />
			<Override slot="text5" />
			<Override slot="text6" />
			<Override slot="text7" />
			<Override slot="text8" />
			<Override slot="text9" />
			<Override slot="box" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});